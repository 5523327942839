export const PERMISSION = 'Permission'
export const ROLE = 'Role'
export const USER = 'User'
export const LESSON = 'Lesson'
export const CONVERSATION = 'Conversation'
export const BACKGROUND = 'Background'
export const FIGURE = 'Figure'
export const FEEDBACK = 'Feedback'
export const VERSION = 'Version'
export const PAGE = 'Page'
