import { MANAGE } from '~/constants/permission-action.constant'
import {
  BACKGROUND,
  CONVERSATION,
  FEEDBACK,
  FIGURE,
  LESSON,
  PAGE,
  PERMISSION,
  ROLE,
  USER,
} from '~/constants/permission-object.constant'

export default [
  {
    title: 'Dashboard',
    icon: 'flaticon2-architecture-and-city',
    route: '/',
  },
  {
    title: 'Quản lý nội dung học',
    icon: 'flaticon2-digital-marketing',
    subMenus: [
      {
        title: 'Bài học',
        route: '/lesson',
        permission: [MANAGE, LESSON],
      },
      {
        title: 'Hội thoại',
        route: '/conversation',
        permission: [MANAGE, CONVERSATION],
      },
      {
        title: 'Bối cảnh',
        route: '/background',
        permission: [MANAGE, BACKGROUND],
      },
      {
        title: 'Nhân vật',
        route: '/figure',
        permission: [MANAGE, FIGURE],
      },
      {
        title: 'Phản hồi',
        route: '/feedback',
        permission: [MANAGE, FEEDBACK],
      },
      {
        title: 'Trang',
        route: '/page',
        permission: [MANAGE, PAGE],
      },
    ],
  },
  {
    title: 'Quản lý người dùng',
    icon: 'flaticon2-user',
    route: '/user',
    permission: [MANAGE, USER],
  },
  {
    title: 'Phân quyền người dùng',
    icon: 'flaticon-web',
    subMenus: [
      {
        title: 'Nhóm người dùng',
        route: '/role',
        permission: [MANAGE, ROLE],
      },
      {
        title: 'Quyền truy cập',
        route: '/permission',
        permission: [MANAGE, PERMISSION],
      },
    ],
  },
]
